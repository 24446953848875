import { IonItem, IonList } from '@ionic/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import { NormalText, SmallText, StrongText, Title } from '../../components/common';
import Basket from '../../lib/basket';
import { withTranslation } from '../../lib/translate';
import { forwardTo } from '../../lib/utils';
import './index.css';

class TileOrderList extends Component {
	constructor(props) {
		super(props);
		this.ionList = null;
		this.orderListItems = null;
	}
	componentDidMount() {
		const item = document.querySelector('.order-list-items');
		const itemList = document.querySelector('.order-list-items > ion-list');
		if (item && itemList) {
			this.ionList = itemList;
		}
	}

	itemList = (__, item, index) => {
		const { profile } = this.props;
		const selectedResturant = Basket.getRestaurant();
		let itemIsDisabled = false;
		if (selectedResturant && selectedResturant.disabled_skus && selectedResturant.disabled_skus.length > 0) {
			itemIsDisabled = selectedResturant.disabled_skus.includes(item.sku);
		}
		if (item.sku === undefined || itemIsDisabled) {
			return <></>;
		}
		if (item !== null) {
			let image = item.itemRichData && item.itemRichData.squareImageUrl ? item.itemRichData.squareImageUrl : '';
			if (image && image.indexOf('http://') !== -1) {
				image = image.replace(/http:\/\//g, 'https://');
			}
			item.selectedMenu = this.props.selectedMenu;
			return (
				<IonItem
					className={`box-with-shadow ${!Basket.isProductUnsnoozed(item) ? 'disabled-element' : ''} `}
					key={index}
					lines="none"
					onClick={() => (Basket.isProductUnsnoozed(item) ? forwardTo('/item-details', item) : null)}
				>
					<div tabIndex="-1" className="order-list-item">
						<div className="item-price">
							<SmallText>{Basket.formatPrice(Basket.getProductCardPrice(item), true)}</SmallText>
							{item.originalPrice ? (
								<>
									<br />
									<SmallText className="original-price">&#163;{item.originalPrice}</SmallText>
								</>
							) : null}
						</div>
						<div className="item-content">
							<StrongText className="primary-color bold">{__(Basket.getProductName(item, profile))}</StrongText>
							<div
								dangerouslySetInnerHTML={{
									__html: Basket.getProductDescription(item, profile).replace(/(<([^>]+)>)/gi, ''),
								}}
							/>
						</div>

						<div className="item-image-wrapper">
							style={
								image && image !== ''
									? { backgroundImage: `url(${image})` }
									: { backgroundSize: 'contain', backgroundImage: `url(${Basket.getRestaurant()?.menu_item_placeholder_image ?? this.props.clientProfile.logo_image})` }
							}
						</div>
					</div>
				</IonItem>
			);
		}
	};
	setBackgroundImage = (category) => {
		if (category) {
			if (!category.menuEntry) {
				let image = category.itemRichData?.rawImageUrl;
				if (image && image.indexOf('http://') !== -1) {
					image = image.replace(/http:\/\//g, 'https://');
				}
				return { backgroundImage: `url(${image})` };
			}
			const item = category.menuEntry.find((el) => el.itemRichData && el.itemRichData.rawImageUrl);
			if (item) {
				let image = item.itemRichData.rawImageUrl;
				if (image && image.indexOf('http://') !== -1) {
					image = image.replace(/http:\/\//g, 'https://');
				}
				return { backgroundImage: `url(${image})` };
			}
			return this.setBackgroundImage(category.menuEntry[0]);
		}
	};

	drawTiles(__, data) {
		const categories = data.menuEntry || data.menuEntryGroups || [];
		return categories.map((el, index) => {
			if (el['@type'] === 'group') {
				return (
					<div className={getConfig().menuDisplayType === 3 ? 'order-categories-two-columns-tiles-wrapper' : 'order-categories-tiles-wrapper'} key={index}>
						<div
							className="order-categories-tile box-with-shadow"
							style={this.setBackgroundImage(el)}
							onClick={() => {
								this.props.selectMenu(index);
								this.ionList.parentElement.parentElement.scrollIntoView();
							}}
						>
							<span className="order-categories-tile-title">{el.name}</span>
						</div>
					</div>
				);
			} else {
				return this.itemList(__, el, index);
			}
		});
	}

	render() {
		const { __, category } = this.props;
		return (
			<div className="order-list-items" id="order-list-items">
				<Title className="category-title">{category.name || null}</Title>
				<IonList lines="none">{this.drawTiles(__, category)}</IonList>
			</div>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		profile: store.profile.profile,
		restaurantsUpdated: store.restaurants.restaurantsUpdated,
		basketUpdated: store.orders.basketUpdated,
		clientProfile: store.common.clientProfile,
	};
};

export default connect(mapStateToProps)(withTranslation(TileOrderList));
