import { IonButton, IonList } from '@ionic/react';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NormalText, Spacer, StrongText } from '../../components/common';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { SubscriptionLine } from '../../components/subscription-item';
import { withTranslation } from '../../lib/translate';
import { forwardTo, go } from '../../lib/utils';
import { setSelectedSubscription } from '../../store/orders/actions';
import { getSubscriptions } from '../../store/subscription/actions';
import './index.css';
const handleMembership = () => forwardTo('/membership-terms');

const ChooseSubscription = ({ __, subscriptions, selectedSubscription, activeSubscription, getSubscriptions, setSelectedSubscription, clientProfile, location, history }) => {
  useEffect(() => {
    getSubscriptions();
  }, []);

  if (activeSubscription && subscriptions.find((subscription) => subscription.id === activeSubscription.id)) {
    forwardTo('/my-membership');
  }

  let imageUrl = null;
  const items = subscriptions.map((e, idx) => {
    const isChecked = idx === selectedSubscription?.idx;
    if (imageUrl === null) {
      imageUrl = e.image;
    }
    const price = e.monthly_cost_inc_vat / 100;
    return SubscriptionLine(
      __,
      () => {
        setSelectedSubscription({ id: e.id, idx });
        handleMembership();
      },
      idx,
      isChecked,
      e.name,
      e.short_description,
      price,
      e.perks,
    );
  });

  const fromChooseSubscriptionMenu = location?.state?.fromChooseSubscriptionMenu;
  const backHandler = () => {
    fromChooseSubscriptionMenu ? go(-2) : history.goBack();
  };
  return (
    <Loading transparent>
      <Layout
        showHamburger
        color="transparent"
        scrollY={false}
        headerTitle={__('MembershipChooser')}
        hideSecondToolbar={true}
        backHandler={backHandler}
      >
        <div className="absolute-content scrollable-y">
          <NormalText className='bold' >{__('Available subscription packages')}</NormalText>
          <Spacer/>
          <div className="subscription-list">
            <IonList>{items}</IonList>
           
          </div>
        </div>
      </Layout>
    </Loading>
  );
};

const mapStateToProps = (store) => ({
  subscriptions: store.subscription.subscriptions,
  selectedSubscription: store.orders.selectedSubscription,
  activeSubscription: store.profile.profile.active_subscription,
  clientProfile: store.common.clientProfile,
});

const mapDispatchToProps = {
  setSelectedSubscription,
  getSubscriptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(ChooseSubscription));
