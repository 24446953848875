import React, { Component } from 'react';
import { IonInput, IonItem } from '@ionic/react';
import { getConfig } from '../../appConfig';
import Mobiscroll from '../../components/mobiscroll';
import './index.css';
import { withTranslation } from '../../lib/translate';
const { SelectOption } = Mobiscroll;

class MobileInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mobile_code: '',
			mobile_value: '',
		};
	}

	render() {
		const { __, mobile_code, mobile_value, setMobileCode, setMobileValue, className } = this.props;
		const countryCodeData = getConfig().country_code_data || [
			{
				text: 'GB +44',
				value: '+44',
			},
			{
				text: 'US +1',
				value: '+1',
			},
		];

		return (
			<>
				<IonItem lines="none" className={`input-field-wrapper inline-input-field-wrapper ${className ? className : ''}`}>
					<div className="mobile-code-selection">
						<SelectOption
							filter={true}
							onSet={(e, val) => setMobileCode(val.getVal())}
							display="center"
							value={mobile_code || countryCodeData[0].value}
							data={countryCodeData}
							onInit={(e, val) => {
								if (!mobile_code) {
									setMobileCode(countryCodeData[0].value);
								}
							}}
							cancelText={__('Cancel')}
							setText={__('Set')}
							filterPlaceholderText={__('Search')}
						/>
					</div>
					<IonInput
						onIonChange={(e) => setMobileValue(e.target.value)}
						clearInput
						required={false}
						type="tel"
						pattern="tel"
						inputmode="tel"
						placeholder={__('Mobile number')}
						value={mobile_value}
					></IonInput>
				</IonItem>
			</>
		);
	}
}

export default withTranslation(MobileInput);
