import React from 'react';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { connect } from 'react-redux';
import { IonButton, IonIcon, IonItem, IonList } from '@ionic/react';
import { forwardTo } from '../../lib/utils';
import { SmallText, StrongText, Title } from '../../components/common';
import moment from 'moment';
import { person } from 'ionicons/icons';
import { getSentGiftVouchers } from '../../store/actions';
import './index.css';

class SentGiftVouchers extends React.Component {
	componentDidMount() {
		if (this.props.auth.loggedIn) {
			this.props.dispatch(getSentGiftVouchers());
		}
	}

	render() {
		const { __ } = this.props;

		return (
			<Layout showHamburger headerWithTitle title={__('Sent vouchers')} color="transparent">
				<div className="absolute-content scrollable-y flex-row-wrapper">
					<Title className="web-only">{__('Sent vouchers')}</Title>
					<div>
						<IonList className="gift-voucher-history-list box-wrapper">
							{this.props.sentGiftVouchers.map((voucher) => (
								<IonItem
									lines="none"
									key={voucher.id}
									className="pointer"
									onClick={() => {
										// forwardTo('/gift-voucher', { voucher, isSent: true, hasEdit: !voucher.is_sent && !voucher.redeemed_on });
										forwardTo('/gift-voucher', { voucher, isSent: true, hasEdit: !voucher.redeemed_on });
									}}
								>
									<IonIcon icon={person} />
									<div className="gift-voucher-user">
										<StrongText className="ellipsis">{voucher.recipient_email}</StrongText>
										{voucher.redeemed_on ? (
											<SmallText className="gift-voucher-date" color="success">
												{__('Redeemed on')}: {moment(voucher.redeemed_on).format('Do MMM yyyy')}
											</SmallText>
										) : (
											<>
												{voucher.is_sent ? (
													<SmallText className="gift-voucher-date">
														{__('Email sent')}: {moment(voucher.send_on_date).format('Do MMM yyyy')}
													</SmallText>
												) : (
													<SmallText className="gift-voucher-date">
														{__('Scheduled Date')}: {moment(voucher.send_on_date).format('Do MMM yyyy')}
													</SmallText>
												)}
											</>
										)}
									</div>
									{voucher.redeemed_on ? (
										<IonButton style={{ height: '24px' }} color="primary" disabled={true}>
											{__('Redeemed')}
										</IonButton>
									) : (
										<>
											{/* {voucher.is_sent ? ( 
											 <IonButton className="gift-voucher-resend-button" style={{ height: '24px' }} color="primary">
												<svg
													style={{ marginRight: '5px' }}
													xmlns="http://www.w3.org/2000/svg"
													width="18"
													height="18"
													viewBox="0 0 24 24"
													strokeWidth="1.5"
													stroke="#fff"
													fill="none"
													strokeLinecap="round"
													strokeLinejoin="round"
												>
													<path stroke="none" d="M0 0h24v24H0z" fill="none" />
													<path d="M12 18h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7.5" />
													<path d="M3 6l9 6l9 -6" />
													<path d="M15 18h6" />
													<path d="M18 15l3 3l-3 3" />
												</svg>
												<SmallText>{__('Resend')}</SmallText>
											</IonButton> 
											 ) : ( */}
											<>
												<IonButton className="gift-voucher-resend-button" style={{ height: '24px' }} color="primary">
													<SmallText>{__('Edit')}</SmallText>
												</IonButton>
											</>
											{/* )}  */}
										</>
									)}
								</IonItem>
							))}
						</IonList>
					</div>
					<div className="flex-min">
						<IonButton color="primary" expand="block" onClick={() => forwardTo('/gift-vouchers')}>
							{__('Send another gift voucher')}
						</IonButton>
					</div>
				</div>
			</Layout>
		);
	}
}

const stateToProps = (state) => {
	return {
		sentGiftVouchers: state.orders.sentGiftVouchers || [],
		auth: state.profile.auth,
	};
};
export default connect(stateToProps)(withTranslation(SentGiftVouchers));
