import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IonItem, IonButton, IonInput, IonGrid, IonCol, IonRow, IonIcon } from '@ionic/react';
import Basket from '../../lib/basket';
import { NormalText, Spacer, StrongText, Subtitle } from '../../components/common';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { forwardTo, isEmptyObject } from '../../lib/utils';
import Loading from '../../components/spinner';
import './index.css';
import { SET_TABLE_PAY_METHOD } from '../../store/constants';
import { chevronForward } from 'ionicons/icons';
import TableBillServiceCharge from '../../components/tableBillServiceCharge';
import Modal from '../../components/modal';
import TableItemsOverview from '../../components/tableItemsOverview';
import CheckoutPay from '../checkout/CheckoutPay';
import { Capacitor } from '@capacitor/core';
import { getConfig } from '../../appConfig';
import Mobiscroll from '../../components/mobiscroll';
import BillPayAmount from '../../components/billPayAmount';
const { SelectOption } = Mobiscroll;

const isWeb = () => Capacitor.getPlatform() === 'web';

class PayTableOverview extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			serviceChargeValue: 0,
			isSetPayMethodModalOpen: false,
			tableMembers: 0,
			payMethod: 0,
		};
	}
	componentDidMount() {
		const { google } = window;
		if (isEmptyObject(Basket.bill_data)) {
			forwardTo('/table-bill-pay');
		} else {
			Basket.setTablePaymentAmount(Basket.bill_total);
		}
		if (isEmptyObject(Basket.getRestaurant()) || !Basket.getRestaurant()) {
			const selectedRestaurant = this.props.restaurants.find((restaurant) => restaurant.name == Basket.bill_data.restaurant_name);
			Basket.setRestaurant(selectedRestaurant);
		}
	
	}
	onPayTableClick = () => {
		forwardTo('/bill-summary');
	};
	setTablePayMethod = () => {
		this.props.dispatch({
			type: SET_TABLE_PAY_METHOD,
			data: {
				pay_method: this.state.payMethod,
				table_bill_id: Basket.bill_data.id,
				total_members_to_pay: parseInt(this.state.tableMembers),
			},
		});
	};
	onServiceChargeSetHandler = (serviceChargeValue) => {
		this.setState({ serviceChargeValue });
	};
	handleSubmit = () => {
		const { cards } = this.props;
		forwardTo('/checkout', { fromTablePay: true });
	};
	forwardToPayment = () => {
		this.handleSubmit();
	};
	formatTablePartyForSelect = () => {
		const { __ } = this.props;
		let arrForSelect = [{ text: __(`Select`), value: null }];
		for (let i = 1; i <= 20; i++) {
			arrForSelect.push({
				text: `${__(`${i}`)}`,
				value: i,
			});
		}
		return arrForSelect;
	};
	componentDidUpdate(prevProps) {
		if (this.props.tableData.total_due !== prevProps.tableData.total_due && !isEmptyObject(this.props.tableData)) {
			// Basket.setBillPayData(this.props.tableData);
			// Basket.setTablePaymentAmount(this.props.tableData.total_due);
		}
	}
	render() {
		const { __, clientSecret, location, profile, dispatch, isTableItemsOverviewVisible } = this.props;
		const { serviceChargeValue } = this.state;
		const billPayOptions = getConfig().billPayOptions;
		const deliveryOption = Basket.getDeliveryOption();
		if (isEmptyObject(Basket.bill_data)) {
			forwardTo('/table-bill-pay');
			return <></>;
		}

		return (
			<Loading transparent>
				<Layout headerWithTitle title={`${__('Table')} ${Basket.getBillTableNumber()}`} color="transparent">
					<div className="absolute-content scrollable-y">
						<div className="bill-pay-title-wrapper">
							<NormalText className="block">
								{__('Table')} {Basket.getBillTableNumber()}
							</NormalText>
							<IonButton fill="clear" color="secondary" className="link underlined" onClick={() => forwardTo('/table-bill-pay')}>
								{__('Wrong table?')}
							</IonButton>
						</div>
						<Spacer size={1} />
						<BillPayAmount />
						<Spacer size={1} />
						<TableItemsOverview />
						<Spacer size={1} />
						<TableBillServiceCharge paymentAmount={Basket.getTableAmountToPay()} serviceChargeSet={this.onServiceChargeSetHandler} />
						<Spacer size={1} />
						{serviceChargeValue > 0 && (
							<>
								<IonGrid className="box-wrapper space-between pointer ">
									<IonRow>
										<IonCol>
											<Subtitle className="bold">{__('Payment amount')}</Subtitle>
										</IonCol>
										<IonCol className="righted">
											<Subtitle className="bold">{Basket.getTotalPlusServiceCharge()}</Subtitle>
										</IonCol>
									</IonRow>
								</IonGrid>
								<Spacer size={1} />
							</>
						)}

						<CheckoutPay clientSecret={clientSecret} dispatch={dispatch} __={__} location={location} profile={profile} isBillPay={true} billTotal={Basket.bill_pay_total} />
						<Spacer size={1} />
						<IonButton className="checkout-btn" color="primary" expand="block" onClick={() => this.forwardToPayment()}>
							{__('Pay with card')}
						</IonButton>
						<Spacer size={1} />
						<StrongText className=" block">{__('Want to split the bill?')}</StrongText>
						<IonGrid className="box-wrapper-multiple-items">
							{billPayOptions.hasSplitByTableMembers && (
								<IonRow className="pointer" onClick={() => this.setState({ payMethod: 1, isSetPayMethodModalOpen: true })}>
									<IonCol size="10">{__('Split with friends')}</IonCol>
									<IonCol size="2" className="righted">
										<IonIcon icon={chevronForward} />
									</IonCol>
								</IonRow>
							)}
							{billPayOptions.hasSplitByItems && (
								<IonRow className="pointer" onClick={() => this.setState({ payMethod: 3, isSetPayMethodModalOpen: true })}>
									<IonCol size="10">{__('Split by items ordered')}</IonCol>
									<IonCol size="2" className="righted">
										<IonIcon icon={chevronForward} />
									</IonCol>
								</IonRow>
							)}
							{billPayOptions.hasSplitByCustomAmount && (
								<IonRow className="pointer" onClick={() => this.setState({ payMethod: 2, isSetPayMethodModalOpen: true })}>
									<IonCol size="10">{__('Split by custom amount')}</IonCol>
									<IonCol size="2" className="righted">
										<IonIcon icon={chevronForward} />
									</IonCol>
								</IonRow>
							)}
						</IonGrid>
					</div>
					<Modal
					
						className={`set-pay-method-modal ${this.state.payMethod != 1 && 'small-modal'}`}
						isOpen={this.state.isSetPayMethodModalOpen}
						onDidDismiss={() => this.setState({ isSetPayMethodModalOpen: false })}

					>
						<Subtitle>{__('Are you sure that you want to set this as your chosen payment method?')}</Subtitle>
						{this.state.payMethod == 1 && (
							<>
								<Spacer />
								<NormalText>{__('Number of table members')}</NormalText>
								<IonItem lines="none" className="box-wrapper inline-box-wrapper">
									<SelectOption
										display="center"
										onSet={(e, a) => this.setState({  tableMembers: a.getVal() })}
										required={true}
										type="number"
										pattern="number"
										inputmode="number"
										data={this.formatTablePartyForSelect()}
										value={this.state.tableMembers}
									/>
									<div>
										<NormalText>{this.state.tableMembers == 1 ? __('Person') : __('People')}</NormalText>
									</div>
								</IonItem>
								<Spacer />
							</>
						)}
						<IonButton disabled={!this.state.tableMembers > 0 && this.state.payMethod == 1} expand="block" color="primary" onClick={() => this.setState({isSetPayMethodModalOpen:false},()=> this.setTablePayMethod())}>
							{__('Confirm')}
						</IonButton>
					</Modal>
				</Layout>
			</Loading>
		);
	}
}

const mapStateToProps = (store) => {
	const { tableData, restaurants } = store.restaurants;
	const { auth, profile } = store.profile;
	const { clientSecret } = store.orders;
	const { isTableItemsOverviewVisible } = store.common;
	return {
		tableData: tableData,
		profile: store.profile.profile,
		cards: store.orders.cards || [],
		auth,
		profile,
		clientSecret,
		isTableItemsOverviewVisible,
		restaurants,
	};
};

export default connect(mapStateToProps)(withTranslation(PayTableOverview));
