import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore from "swiper";
import {Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules';
import './index.css';
import 'swiper/css'
import 'swiper/css/pagination'

SwiperCore.use([Navigation , Pagination, EffectFade, Autoplay]);

const Slider = ({children, settings, className}) => {
  const sliderOptions = {
    slidesPerView: 1,
    pagination: {
      clickable: true,
      el: `swiper-container swiper-container-testClass`,
      bulletClass: `swiper-pagination-bullet swiper-pagination-testClass`
   },
    navigation: true,
    loop: true,
    ...settings
  };

  return (
    <div className='slider-wrap'>
      <Swiper className={className} {...sliderOptions} >
        {children}
      </Swiper>
    </div>
  );
};

export {SwiperSlide as Slide};
export default Slider;