import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';
import { loading, setCommonProp } from '../../store/actions';
import { forwardTo, getDefaultRoute } from '../../lib/utils';
import { IonButton } from '@ionic/react';
import { Spacer, Subtitle, Title } from '../../components/common';
import './index.css';
class ErrorPage extends React.Component {
	componentDidMount() {
		if (!this.props.showErrorPage) {
			const defaultRoute = getDefaultRoute(this.props.navConfig);
			forwardTo(defaultRoute.path);
		}
		this.props.dispatch(setCommonProp('initLoading', false));
		this.props.dispatch(loading(false));
	}
	render() {
		const { __ } = this.props;
		return (
			<div className="error-page-container">
				<Title>{__('Oops! Something went wrong.')}</Title>
				<Spacer size={1} />
				<Subtitle>{__('We encountered an error while processing your request.')}</Subtitle>
				<Spacer size={1} />
				<Subtitle>{__('Please try again later.')}</Subtitle>
				<Spacer size={2} />
				<IonButton onClick={() => window.location.reload()} color="dark">
					{__('Try again')}
				</IonButton>
			</div>
		);
	}
}
const mapStateToProps = (store) => {
	return {
		showErrorPage: store.common.showErrorPage,
		navConfig: store.common.navConfig,
	};
};
export default connect(mapStateToProps)(withTranslation(ErrorPage));
