import React from 'react';
import './index.css';

const DashboardBar = ({ onDismiss, handleClick, children }) => (
	<div className="dashboard-bar-wrapper">
		<div className="dashboard-scrim" onClick={onDismiss ? onDismiss : null}></div>
		<div className="dashboard-bar" onClick={handleClick}>
			{children}
		</div>
	</div>
);

export default DashboardBar;
