import React from 'react';
import { IonModal, IonButton, IonIcon } from '@ionic/react';
import backArrow from '../../assets/images/icons/back_arrow.svg'
import './index.css';
import { isNativeIos } from '../../lib/utils';
import { Title } from '../common';

export default class Modal extends React.Component {
	render() {
		const { children, title, action, actionLabel, className, scrollable, showCancelButton, cancelButtonLabel, showBackArrow, ...rest } = this.props;
		const { onDidDismiss } = rest;

		return (
			<IonModal className={'modal-classic modal-wrapper' + (className ? ' ' + className : '')} {...rest}>
				<div className={'modal-classic-wrapper' + (scrollable ? ' scrollable-y' : '')}>
					{onDidDismiss && !showCancelButton ? (
						<>
							{showBackArrow ? (
								<div className={`modal-back-arrow-closer ${isNativeIos() ? 'ios-modal-back-arrow-close':''}`} onClick={onDidDismiss}>
									<IonIcon color="primary" icon={backArrow} />
								</div>
								
							) : (
								<div className={`modal-classic-closer ${isNativeIos() ? 'ios-modal-classic-closer':''}`} onClick={onDidDismiss}>
									<ion-icon icon="close-outline" />
								</div>
							)}
						</>
					) : null}
					{title ? (
						<div className="modal-classic-header">
							<Title>{title}</Title>
						</div>
					) : null}
					<div className={`modal-classic-content ${isNativeIos() ? 'ios-modal-classic-content':''}`}>{children}</div>
					{action ? (
						<div className="modal-classic-action">
							<IonButton expand="block" color="primary" onClick={action}>
								{actionLabel || '--'}
							</IonButton>
						</div>
					) : null}
					{showCancelButton && (
						<div className="modal-classic-action">
							<IonButton expand="block" color="primary" fill="clear" className="link underlined" onClick={action}>
								{cancelButtonLabel || 'cancel'}
							</IonButton>
						</div>
					)}
				</div>
			</IonModal>
		);
	}
}
